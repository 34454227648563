<template>
  <b-row class="match-height">
    <b-col lg="12">
      <ScoutList />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ScoutList from '@/components/company/scout/ScoutList.vue'

export default {
  components: {
    BRow,
    BCol,
    ScoutList,
  },
}
</script>

<style lang="scss">
</style>
